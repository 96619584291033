import { MouseEvent,useState } from 'react';
import MenuIcon from '@mui/icons-material/Menu';
import { Button, Menu, MenuItem } from '@mui/material';
import Link from 'next/link';

const MainMenu = () => {
    const [isOpen, setIsOpen] = useState<null | HTMLElement>(null);
    const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
        setIsOpen(event.currentTarget);
    };

    return (<>
        <div>
            <Button
                data-cy="menu-button"
                id="basic-button"
                aria-controls={Boolean(isOpen) ? 'basic-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={Boolean(isOpen) ? 'true' : undefined}
                onClick={handleClick}
            >
                <MenuIcon />

            </Button>
            <Menu
                id="basic-menu"
                anchorEl={isOpen}
                open={Boolean(isOpen)}
                onClose={() => setIsOpen(null)}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}
            >
                <MenuItem>
                    <Link href={'/auth/login'}>Logga In</Link>
                </MenuItem>
            </Menu>
        </div>
    </>);
};

export default MainMenu;