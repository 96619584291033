import { Grid, Typography } from '@mui/material';
import { Trans, useTranslation } from 'next-i18next';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';

import Layout from '../layouts/Layout';

export default function Authentication() {
    const { t } = useTranslation('common');

    return (
        <Layout
            includePrivacyPolicy
            title={t('/.title')}
            includeBanner
            linkToStartPage
        >
            <Grid data-non-pii container alignItems="center" justifyContent="center" spacing={4}>
                <Grid item xs={8}>
                    <Trans
                        i18nKey="/.body"
                        t={t}
                        components={[
                            <Typography sx={{ paddingTop: 2 }} key={1} />,
                            <Typography sx={{ paddingTop: 2 }} key={1} />,
                            <ul key={2} />,
                        ]}
                    />
                </Grid>
            </Grid>
        </Layout>
    );
}

export async function getStaticProps(context: { locale: string }) {
    const { locale = 'se' } = context;

    return {
        props: {
            ...(await serverSideTranslations(locale, ['common'])),
        },
    };
}
