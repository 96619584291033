import React, { CSSProperties, PropsWithChildren } from 'react';
import LaunchIcon from '@mui/icons-material/Launch';
import { Box, Grid, Typography, TypographyProps } from '@mui/material';
import Head from 'next/head';
import Image from 'next/image';
import Link from 'next/link';

import MainMenu from './MainMenu';

export const PageTitle = (props: TypographyProps) => (
    <Typography sx={{
        textAlign: 'center',
        marginBottom: 2,
        fontWeight:400,
    }} variant="h2" {...props}>
    </Typography>
);

const returnLogoLink = (linkToBes: boolean, linkToStartPage: boolean) => {
    switch (true) {
        case linkToBes:
            return 'https://xn--besiktningsfretaget-16b.se/';
        case linkToStartPage:
            return '/';
        default :
            return '/portal';
    }
};

export const Layout = (
    {
        children,
        title,
        mainStyle = {},
        startAtTop = false,
        includePrivacyPolicy = false,
        includeBanner = false,
        linkToStartPage = false,
        linkToBes = false,
        hideMenu = false,
        showAlternateIcon = false,
        showBackground = false,
    }: PropsWithChildren<{
        title?: string;
        startAtTop?: boolean;
        mainStyle?: React.CSSProperties;
        includePrivacyPolicy?: boolean;
        includeBanner?: boolean;
        linkToStartPage?: boolean;
        linkToBes?: boolean;
        hideMenu?: boolean;
        showAlternateIcon?: boolean;
        showBackground?: boolean;
    }>) => {
    const getPaddingBelowMenu = () => {
        if (includeBanner) {
            return '2rem 0';
        }

        if (startAtTop) {
            return undefined;
        }

        if (hideMenu) {
            return '3rem 0';
        }

        return '10rem 0';
    };

    const style: CSSProperties = {
        ...{
            minHeight: '100vh',
            display: 'flex',
            flexDirection: 'column',
        },
        ...(showBackground ? {
            backgroundImage: 'url("/houses-background.svg")',
            backgroundPosition: 'bottom',
            backgroundRepeat: 'repeat-x',
            backgroundSize: '8000px',
        } : {}),
    };

    return (
        <>
            <Head>
                <title>Besiktningssystemet</title>
            </Head>
            <main
                style={style}
            >
                {includeBanner && (
                    <Box height="350px" width="100%" maxHeight="450px" position="relative">
                        <Image
                            src="/bes-banner.jpeg"
                            alt="Banner"
                            fill
                            style={{
                                objectPosition: '50% 20%',
                                objectFit: 'cover',
                            }}
                        />
                    </Box>
                )}
                <Box
                    id="system-header"
                    sx={{
                        position: 'fixed',
                        zIndex: 10,
                        top: 0,
                        left: 0,
                        right: 0,
                        padding: '7px 5px 2px 9px',
                        marginBottom: '40px',
                        borderBottom: '1px solid #dcdcdc',
                        backgroundColor: 'background.default',
                    }}
                >
                    <Grid
                        container
                        item
                        sx={{
                            paddingBottom: 0.35,
                        }}
                        display="flex"
                        alignItems="center"
                        justifyItems="center"
                        xs={12}
                    >
                        <Grid item xs={6}>
                            <Link href={returnLogoLink(linkToBes, linkToStartPage)}>
                                <Box
                                    sx={{
                                        display: 'flex',
                                    }}
                                >
                                    <Image
                                        src={!showAlternateIcon ?
                                            '/logo-red.png' :
                                            '/logo-blue.svg'
                                        }
                                        alt=""
                                        width={2260 / 8}
                                        height={384 / 8}
                                    />
                                </Box>
                            </Link>
                        </Grid>
                        {!hideMenu && <Grid
                            item
                            xs={6}
                            display="flex"
                            justifyContent="flex-end"
                            alignItems="center"
                        >
                            <Grid item mr={1}>
                                <MainMenu />
                            </Grid>
                        </Grid>}
                    </Grid>
                </Box>

                <Box
                    sx={{
                        padding: getPaddingBelowMenu(),
                        flex: 1,
                        display: 'flex',
                        justifyContent: 'start',
                        flexDirection: 'column',
                        alignItems: 'center',
                        ...mainStyle,
                    }}
                >
                    {title && <PageTitle>{title}</PageTitle>}
                    <Box
                        sx={{
                            flex: 1,
                            display: 'flex',
                            justifyContent: 'center',
                            maxWidth: '1400px',
                            width: '100%',
                            height: '100%',
                            padding: '2em',
                            margin: '0 auto 0',
                        }}
                    >
                        {children}
                    </Box>
                    {includePrivacyPolicy && (
                        <Box sx={{ marginTop: 10 }}>
                            <Link
                                style={{ display: 'flex', alignItems: 'center' }}
                                href="https://besiktningsföretaget.se/integritetspolicy"
                            >
                                Integritetspolicy
                                <LaunchIcon sx={{ marginLeft: 0.5 }} fontSize="small" />
                            </Link>
                        </Box>
                    )}
                </Box>
            </main>
        </>
    );
};

export default Layout;
